import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/cozma-katalin.jpg";
  const fullName = "Cozma Katalin";
  const zoomUrl =
    "https://us02web.zoom.us/j/84882160768?pwd=THIwUE9HTzh6VDZxYXlnNnhMVjdnQT09";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "848 8216 0768";
  const zoomMeetingPasscode = "443955";
  const birthDate = "September 16, 1962";
  const deathDate = "December 5, 2020";
  const memorialDate = "Saturday January 2nd, 2021";
  const memorialTime = "9:30AM EST / 3:30pm GMT+1";
  const inviteText = `Please join us in remembering, celebrating, and or mourning Katalin. So many loved her and she loved so many. The world is less than without her. Her life was a blessing. Her memory is a treasure. She is loved beyond words and missed beyond measure.`;
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/fntpk7ti7";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rf7n4329ikl9p3d0yul75uf98adc318xxth8g3vp";
  const recordingEmbedDirectUrl = [
    "https://storage.googleapis.com/afterword-static/recordings/Cozma%20Katalin.mp4",
    "https://storage.googleapis.com/afterword-static/recordings/CozmaKatalinService.mp4",
    "https://storage.googleapis.com/afterword-static/recordings/Cozma%20Katalin%20Placement%20with%20Song.mp4",
  ];

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
    >
      <hr />
      <p>Drága Családom és Barátaim!</p>
      <p>
        Mély fájdalommal és megtört szívvel tudatjuk mindazokkal akik ismerték
        és szerették, hogy drága Édesanyám és Nagymama
      </p>
      <p>Cozma Katalin</p>
      <p>
        2020. December.05 –én tragikus hirtelenséggel végleg eltávozott közülünk
        58. életévében. Hamvasztás utáni utolsó útjára csak szűk családi körben
        fogjuk tudni elkísérni a jelen körülményekre való tekintettel. Mindazok
        akik rész vesznek a temetésen egy virtuális közvetítés keretein belül
        kísérhetik figyelemmel 2021. Január 2 –án, 15:30 -kor (GMT+1). A római
        katolikus egyház szertartása szerint végső búcsút veszünk szerettünktől
        a New York –ban található a St. Michael’s Templomból, mely angol nyelven
        fog megtörténni.{" "}
      </p>
      <p>Drága emléke örökké szívünkben fog élni!</p>
    </StoryPage>
  );
};

export default ThisPage;
